<template>
  <div>
    <carousal-content page="concierge" />
    <!-- ==== work section start ==== -->
    <section class="section__space work__three bg__img">
      <div class="container">
        <div class="work__three__area">
          <div class="section__header">
            <h2
              class="
                primary
                text-center
                content__space
                wow
                animate__animated animate__fadeInUp
              "
            >
              How ShipIndia Works
            </h2>
            <h3 class="text-center wow animate__animated animate__fadeInUp">
              AORA is a third party service. Terms and conditions of usage can
              be found at shipindia.co
            </h3>
          </div>
          <div class="row section__space__top section__header__space">
            <div class="col-md-6 col-lg-4">
              <div
                class="
                  work__three__item
                  text-center
                  column__space
                  wow
                  animate__animated animate__fadeInUp
                  ship__img__alt
                "
              >
                <img
                  src="@/assets/images/icons/shop.png"
                  alt="shop"
                  class="section__header__space"
                />
                <h3 class="text-center content__space">
                  Step 1: Shop & Browse
                </h3>
                <p class="secondary text-center">
                  Browse from your favourite US retailers, and share the product
                  URL with us
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div
                class="
                  work__three__item
                  text-center
                  column__space
                  wow
                  animate__animated animate__fadeInUp
                "
              >
                <img
                  src="@/assets/images/icons/buy.png"
                  alt="buy"
                  class="section__header__space"
                />
                <h3 class="text-center content__space">
                  Step 2: We Buy & Ship
                </h3>
                <p class="secondary text-center">
                  We’ll provide you a quote with your product price, shipping
                  costs, agent fees and taxes
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div
                class="
                  work__three__item
                  text-center
                  wow
                  animate__animated animate__fadeInUp
                "
              >
                <img
                  src="@/assets/images/icons/sit.png"
                  alt="shop"
                  class="section__header__space"
                />
                <h3 class="text-center content__space">
                  Step 3: Sit Back & Relax
                </h3>
                <p class="secondary text-center">
                  We’ll manage everything from buying to doorstep delivery!
                </p>
              </div>
            </div>
          </div>
          <div class="text-center work__alt__button">
            <a href="index.html" class="button primary">Find Out More</a>
          </div>
        </div>
      </div>
      <div class="shipping__animation">
        <!-- <img src="assets/images/shipping/ellipse-large.png" alt="Ellipse" class="ellipse--large" />
            <img src="assets/images/shipping/ellipse.png" alt="Ellipse" class="ellipse" data-wow-delay="1s" /> -->
        <img
          src="@/assets/images/shipping/ellipse-small.png"
          alt="Ellipse"
          class="ellipse--small"
        />
      </div>
    </section>
    <!-- ==== #work section end ==== -->

    <!-- ==== video section end ==== -->
    <section class="video section__space">
      <div class="container">
        <div class="video__area">
          <div class="row d-flex align-items-center">
            <div class="col-lg-6">
              <div
                class="
                  video__thumb
                  column__space
                  wow
                  animate__animated animate__fadeInUp
                "
              >
                <img src="@/assets/images/feature/video-bg.png" alt="video" />
                <div class="video-btn">
                  <a
                    class="video-popup"
                    href="https://www.youtube.com/watch?v=uCik8-i2Hcc"
                    title="YouTube video player"
                  >
                    <i class="fas fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-5 offset-lg-1">
              <div class="video__content">
                <h6
                  class="content__space wow animate__animated animate__fadeInUp"
                >
                  How It Works
                </h6>
                <h2
                  class="
                    primary
                    content__space
                    wow
                    animate__animated animate__fadeInUp
                  "
                >
                  Watch The Video
                </h2>
                <p class="tertiary wow animate__animated animate__fadeInUp">
                  Instant Quote: a simple, painless solution for shipping your
                  boxes and small personal items to over
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ==== #video section end ==== -->

    <!-- ==== buy section start ==== -->
    <section
      class="section__space__bottom rate__two rate__three bg__img"
      :style="{ backgroundImage: `url(${shapeTwo})` }"
    >
      <div class="container">
        <div class="rate__two__area">
          <div class="section__header section__space__bottom">
            <h2
              class="
                text-start
                wow
                animate__animated animate__fadeInUp
                content__space
              "
            >
              ShipIndia Buy
            </h2>
            <p class="tertiary text-start">
              Instant Quote: a simple, painless solution for shipping your boxes
              and small personal items to over
            </p>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-3 col-xxl-3 alt__space">
              <div
                class="rate__two__item wow animate__animated animate__fadeInUp"
              >
                <img
                  src="@/assets/images/icons/buy-two.png"
                  alt="Save More"
                  class="section__header__space"
                />
                <h5 class="content__space">Buy For You</h5>
                <p>
                  Purchase on your behalf. No U.S. credit card or address
                  required..
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 col-xxl-3 alt__space">
              <div
                class="rate__two__item wow animate__animated animate__fadeInUp"
                data-wow-delay="0.1s"
              >
                <img
                  src="@/assets/images/icons/personalized.png"
                  alt="Save More"
                  class="section__header__space"
                />
                <h5 class="content__space">Personalised Service</h5>
                <p>
                  Aora's team will keep you updated on the status of your
                  package every step of the way
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 col-xxl-3 alt__space">
              <div
                class="rate__two__item wow animate__animated animate__fadeInUp"
                data-wow-delay="0.2s"
              >
                <img
                  src="@/assets/images/icons/automatic.png"
                  alt="Save More"
                  class="section__header__space"
                />
                <h5 class="content__space">Automatic Refunds</h5>
                <p>
                  Automatically refund in the event of excess weight charged
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 col-xxl-3 alt__space">
              <div
                class="
                  rate__two__item
                  wow
                  animate__animated animate__fadeInUp
                  ship__img__alt
                  rate__img__alt rate__img__alt__th
                "
                data-wow-delay="0.3s"
              >
                <img
                  src="@/assets/images/icons/transparency.png"
                  alt="Save More"
                  class="section__header__space"
                />
                <h5 class="content__space">Transparency, Peace of Mind</h5>
                <p>
                  All prices upfront and handle everything from purchase to
                  delivery to your doorstep.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shipping__animation">
        <img
          src="@/assets/images/shipping/orange-small.png"
          alt="Ellipse"
          class="ellipse--small"
        />
      </div>
    </section>
    <!-- ==== #buy section end ==== -->

    <!-- ==== cost section start ==== -->
    <section class="cost__alt section__space">
      <div class="container">
        <div class="cost__alt__area">
          <div class="section__header section__space__bottom">
            <h2
              class="
                text-center
                content__space
                wow
                animate__animated animate__fadeInUp
              "
            >
              ShipIndia's Rates & Fees
            </h2>
            <p
              class="
                text-center
                tertiary
                wow
                animate__animated animate__fadeInUp
              "
            >
              Instant Quote: a simple, painless solution for shipping your boxes
              and small personal items to over
            </p>
          </div>
          <div class="row section__header__space">
            <div class="col-md-6 col-lg-4">
              <div
                class="
                  cost__alt__item cost__alt__item_two
                  wow
                  animate__animated animate__fadeInUp
                "
              >
                <h3 class="text-center cta__space">Product Cost</h3>
                <div class="boxe cta__space">
                  <img src="@/assets/images/icons/dollar.png" alt="dollar" />
                </div>
                <p>
                  Prices charged by U.S. online stores for your items. Depending
                  on your item, Singapore sales tax may apply. Add items to your
                  cart to see a full breakdown of costs.
                </p>
                <a href="#" class="button primary">Shop Now</a>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div
                class="cost__alt__item wow animate__animated animate__fadeInUp"
              >
                <h3 class="text-center cta__space">Shipping</h3>
                <div class="boxe cta__space">
                  <h6 class="text-center">$1.49 / 100g</h6>
                </div>
                <p class="section__header__space">
                  Shipping costs are based on actual weight of your item which
                  includes free consolidation, repacking and automatic refunds.
                </p>
                <p>*Subject to a minimum of 500g per order</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div
                class="cost__alt__item wow animate__animated animate__fadeInUp"
              >
                <h3 class="text-center cta__space">Service Fee</h3>
                <div class="boxe cta__space">
                  <h6 class="text-center">5% Service Fee</h6>
                </div>
                <p class="section__header__space">
                  SWe handle the entire buying process from purchasing,
                  logistics, shipping and delivery to your doorstep.
                </p>
                <p>*Subject to a minimum of SGD 9 per order</p>
              </div>
            </div>
          </div>
          <div
            class="
              text-center
              work__alt__button
              wow
              animate__animated animate__fadeInUp
            "
          >
            <a href="index.html" class="button">Find Out More</a>
          </div>
        </div>
      </div>
    </section>
    <!-- ==== #cost section end ==== -->

    <!-- ==== sign up section start ==== -->
    <section class="sign__up">
      <div class="container">
        <div class="sign__up__area">
          <div class="row d-flex align-items-center">
            <div class="col-lg-8">
              <div class="sign__up__content column__space">
                <h2
                  class="primary wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  Sign up now to enjoy <br />
                  10% off your first shipment!
                </h2>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="sign__up__cta text-start text-lg-end">
                <a
                  href="services.html"
                  class="button button--primary button--secondary"
                  >Sign Up Now</a
                >
              </div>
            </div>
          </div>
          <img
            src="@/assets/images/sign-up/plane.png"
            alt="Flying Plane"
            class="flying-plane"
          />
          <img src="@/assets/images/sign-up/leaf.png" alt="Leaf" class="leaf" />
        </div>
      </div>
    </section>
    <!-- ==== #sign up section end ==== -->

    <!-- ==== faq section start ==== -->
    <section class="faq__alt section__space__bottom">
      <div class="container">
        <div class="faq__alt__area">
          <div class="section__header section__space__bottom">
            <h2
              class="
                text-center
                wow
                animate__animated animate__fadeInUp
                content__space
              "
            >
              ShipIndia's FAQ
            </h2>
            <p class="tertiary text-center">
              Instant Quote: a simple, painless solution for shipping your boxes
              and small personal items to over
            </p>
          </div>
          <div class="faq__tabs__wrapper">
            <div
              class="accordion accordion-flush wow fadeInUp"
              data-wow-duration="0.6s"
              id="accordionFlushExample"
            >
             <tab-content visible="true">
              <template v-slot:heading
                >What Responsibilities Does Your Landlord Have?</template
              >
              <template v-slot:content>
                Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content>
            <tab-content>
              <template v-slot:heading
                > What Responsibilities Do Tenants Have on Rented Properties?</template
              >
              <template v-slot:content
                >Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content>
            <tab-content>
              <template v-slot:heading
                >What Claims Can You Claim Compensation for?</template
              >
              <template v-slot:content
                >Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content>
            <tab-content>
              <template v-slot:heading
                >How long do I have to claim?</template
              >
              <template v-slot:content
                >Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content>
             <tab-content>
              <template v-slot:heading
                > How long do claims usually take to settle?</template
              >
              <template v-slot:content
                >Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content>
             <tab-content>
              <template v-slot:heading
                > What if I am behind on my rent?</template
              >
              <template v-slot:content
                >Just as the landlords, every tenant has a set of legal
                responsibilities towards their rented property. However, a
                rental agreement might contain a few clauses that supersede
                these responsibilities. There are certain renters who have their
                own agreements; for example, the housing association
                compensation policy that tenants are required to sign before
                moving into the property. Nonetheless, if there are no other
                agreements in place, the law stipulates the following:
                <ul>
                  <li>
                    The tenant is responsible for performing certain maintenance
                    on the property, like changing any fuses that blow or
                    lightbulbs that
                  </li>
                  <li>
                    The tenant also has to make responsible use of all fittings
                    and fixtures on the property, such as making sure they put
                    the right kind of garbage into
                  </li>
                </ul></template
              >
            </tab-content>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ==== #faq section end ==== -->
  </div>
</template>

<script>
import { WOW } from "wowjs";
import shapeTwo from "../assets/images/feature/shape-two.png";
import CarousalContent from "../components/CarousalContent.vue";
import TabContent from "../components/TabContent.vue";
export default {
  components: { CarousalContent, TabContent },
  data() {
    return {
      shapeTwo: shapeTwo,
    };
  },
  mounted() {
    new WOW().init();
  },
};
</script>

<style>
</style>
